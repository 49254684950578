import React from "react";
import { Link } from "../components/links";
import { termsAndConditions, termsAndConditionsP2 } from "../data/components/footer";
import "../styles/pages/terms-and-conditions.scss";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <div className="terms-and-conditions__inner">
        <h3>Terms and Conditions:</h3>
        <p>{termsAndConditions}</p>
        <Link
          isOutbound
          to="https://files.adviserinfo.sec.gov/IAPD/Content/Common/crd_iapd_Brochure.aspx?BRCHR_VRSN_ID=854347"
        >
          Form ADV Part 2A
        </Link>
        {termsAndConditionsP2.map((item, index) => (
          <div key={`${"pcm-" + item.title + index}`}>
            <h3>{item.title}</h3>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsAndConditions;
